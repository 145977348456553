import React, { useEffect } from "react"


const Messenger = () => {
	 useEffect(() => {
    window.fbAsyncInit = function() {
      window.FB.init({
        xfbml            : true,
        version          : 'v8.0'
      });
    };

	  (function(d, s, id) {
	  var js, fjs = d.getElementsByTagName(s)[0];
	  if (d.getElementById(id)) return;
	  js = d.createElement(s); js.id = id;
	  js.async = true;
	  js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
	  fjs.parentNode.insertBefore(js, fjs);
	}(document, 'script', 'facebook-jssdk'));
});

	return (
    <>
      <div id="fb-root" />
      <div
        className="fb-customerchat"
        attribution="setup_tool"
        page_id="106524054515037"
        theme_color="#09175E"
      ></div>
    </>
  )
}

export default Messenger